import React from 'react';
import { PageProps } from 'gatsby';

import { WithTranslation, Trans, withTranslation } from 'react-i18next';
import Layout, { LayoutOptions } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import CommonData from '../../data/common/common';
import { useLocaleContext } from '../../context/Locale';
import Link from '../../components/Link/Link';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import { renderBodyWithList } from '../../shared/utils/content';
import { getTableRows } from '../../shared/utils/table';
import { Accordion, AccordionItem } from '../../components/Accordion/Accordion';
import './style.scss';

type Props = PageProps & WithTranslation;

const PrivacyStatement: React.FC<Props> = (props: Props) => {
  const { t, location } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
  };

  const quickLinks = (() => {
    const links: any = t('quickLinks', { returnObjects: true });

    return Object.keys(links).map((objKey: any) => ({
      href: `#${links[objKey].id}`,
      label: `${links[objKey].label}`,
    }));
  })();

  const getQuickLinks = (links: any[]) => {
    return links.map((link) => {
      return (
        <a key={link.href} href={`${link.href}`}>
          {link.label}
        </a>
      );
    });
  };

  const getQuickLinksColumn = () => {
    const halfLength = Math.ceil(quickLinks.length / 2);
    const firstHalf = quickLinks.slice(0, halfLength);
    const secondHalf = quickLinks.slice(halfLength, quickLinks.length);
    const columns = [];
    if (firstHalf) {
      columns.push(
        <div key="01-block" className="column column-50 PrivacyStatement__quick-links-block">
          {getQuickLinks(firstHalf)}
        </div>
      );
    }

    if (secondHalf) {
      columns.push(
        <div key="02-block" className="column column-50 PrivacyStatement__quick-links-block">
          {getQuickLinks(secondHalf)}
        </div>
      );
    }

    return columns;
  };

  const generateList = (list: string[]) => {
    return (
      <ul>
        {list.map((str, i) => {
          return <li key={`${str}-${i}`}>{str}</li>;
        })}
      </ul>
    );
  };

  const emails = {
    mortgageServicing: t(`${LocaleNameSpaceKeys.COMMON}:emails.mortgageServicing`),
    privacyOfficer: t(`${LocaleNameSpaceKeys.COMMON}:emails.privacyOfficer`),
    tlc: t(`${LocaleNameSpaceKeys.COMMON}:emails.tlc`),
  };

  return (
    <Layout options={layoutOptions} className="PrivacyStatement">
      <div className="PrivacyStatement__generic-gap">
        <p className="h6">
          <Trans t={t} i18nKey="dates" />
        </p>
        <h2>{t('introBlock.heading')}</h2>
        <p>
          <Trans i18nKey="introBlock.description" t={t} />
        </p>
        <div className="grid-container">
          <div className="row row-wrap PrivacyStatement__quick-links-wrapper">
            {getQuickLinksColumn()}
          </div>
        </div>
      </div>
      <section id={t('quickLinks.accountability.id')} className="PrivacyStatement__generic-gap">
        <h2>{t('accountability.heading')}</h2>
        <p>{t('accountability.description')}</p>
        <table className="privacy-accountability" aria-labelledby="heading-accountability">
          {/* <thead>
            <tr>
              <th colSpan={2}>{t('accountability.tableTitle')}</th>
            </tr>
          </thead> */}
          <caption>{t('accountability.tableTitle')}</caption>
          <tbody>
            {/* <tr>
              <td>{t(`${LocaleNameSpaceKeys.COMMON}:phone`)}</td>
              <td>{CommonData.telephoneNumber.privacyDept}</td>
            </tr> */}
            <tr>
              <td>{t(`${LocaleNameSpaceKeys.COMMON}:email`)}</td>
              <td>
                <ExternalLink
                  href={`mailto:${emails.privacyOfficer}`}
                  aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${
                    emails.privacyOfficer
                  }`}
                >
                  {emails.privacyOfficer}
                </ExternalLink>
              </td>
            </tr>
            <tr>
              <td>{t(`${LocaleNameSpaceKeys.COMMON}:mailingAddress`)}</td>
              <td>
                <p>
                  {t(`${LocaleNameSpaceKeys.COMMON}:haventreeBank`)}
                  <br />
                  {t(`${LocaleNameSpaceKeys.COMMON}:mailingDepartments.toronto.privacyOfficer`)}
                  <br />
                  {t(`${LocaleNameSpaceKeys.COMMON}:poAddresses.toronto`)}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <Trans t={t} i18nKey="accountability.descriptionTwo">
            <Link to={getPathFromRouteKey(RouteKeys.CONTACT_US.COMPLAINTS_HANDLING)} />
            <ExternalLink href={CommonData.websites.opcc.contact[activeLocaleTag]} />
          </Trans>
        </p>
      </section>
      <section
        id={t('quickLinks.whatIsPersonalInformation.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2>{t('personalInformation.heading')}</h2>
        <p>
          <Trans t={t} i18nKey="personalInformation.description" />
        </p>

        <p>{t('personalInformation.examples.listBlock.heading')}</p>
        {generateList(
          t('personalInformation.examples.listBlock.list', {
            returnObjects: true,
          })
        )}
      </section>

      <section id={t('quickLinks.consent.id')} className="PrivacyStatement__generic-gap">
        <h2>{t('consent.heading')}</h2>
        {renderBodyWithList(t('consent.content', { returnObjects: true }))}
      </section>

      <section
        id={t('quickLinks.howWeCollectYourPersonalInformation.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2>{t('howWeCollectYourPersonalInformation.heading')}</h2>
        {renderBodyWithList(
          t('howWeCollectYourPersonalInformation.content', { returnObjects: true })
        )}
      </section>

      <section
        id={t('quickLinks.thePersonalInformationWeCollect.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2 id="heading-info-we-collect">{t('thePersonalInformationWeCollect.heading')}</h2>
        <p>{t('thePersonalInformationWeCollect.description')}</p>
        <table
          className="privacy-personal-information-we-collect"
          aria-labelledby="heading-info-we-collect"
        >
          <tbody>
            {getTableRows(t('thePersonalInformationWeCollect.table', { returnObjects: true }), {
              useDangerousHTML: false,
            })}
          </tbody>
        </table>
        <p>
          <Trans t={t} i18nKey="thePersonalInformationWeCollect.descriptionTwo" />
        </p>
      </section>

      <section
        id={t('quickLinks.howWeUseYourPersonalInformation.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2 id="heading-info-we-use">{t('howWeUseYourPersonalInformation.heading')}</h2>
        <p>{t('howWeUseYourPersonalInformation.description')}</p>
        <table
          className="privacy-personal-information-we-collect"
          aria-labelledby="heading-info-we-use"
        >
          <tbody>
            {getTableRows(t('howWeUseYourPersonalInformation.table', { returnObjects: true }), {
              useDangerousHTML: false,
            })}
          </tbody>
        </table>
      </section>

      <section
        id={t('quickLinks.howWeTransferandDiscloseYourPersonalInformation.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2>{t('howWeTransferAndDiscloseYourPersonalInformation.heading')}</h2>
        <p>
          <Trans t={t} i18nKey="howWeTransferAndDiscloseYourPersonalInformation.description" />
        </p>

        {/* privacy-transfer-disclose-personal-info */}
        <Accordion allowZeroExpanded allowMultipleExpanded>
          {(t('howWeTransferAndDiscloseYourPersonalInformation.sections', {
            returnObjects: true,
          }) as any[]).map((section: any) => (
            <AccordionItem buttonOptions={{ label: section.heading }} key={section.heading}>
              <table className="privacy-transfer-disclose-personal-info">
                <tbody>{getTableRows(section.table, { useDangerousHTML: false })}</tbody>
              </table>
            </AccordionItem>
          ))}
        </Accordion>
      </section>

      <section
        id={t('quickLinks.howWeProtectYourPersonalInformation.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2>{t('howWeProtectYourPersonalInformation.heading')}</h2>
        {renderBodyWithList(
          t('howWeProtectYourPersonalInformation.content', { returnObjects: true })
        )}
      </section>

      <section
        id={t('quickLinks.retentionOfSensitivePersonalInformation.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2 id="heading-info-retention">{t('retentionOfSensitivePersonalInformation.heading')}</h2>
        <p>
          <Trans t={t} i18nKey="retentionOfSensitivePersonalInformation.description" />
        </p>
        <table
          className="retention-sensitive-personal-info"
          aria-labelledby="heading-info-retention"
        >
          <tbody>
            {getTableRows(
              t('retentionOfSensitivePersonalInformation.table.rows', { returnObjects: true }),
              { useDangerousHTML: false }
            )}
          </tbody>
        </table>
        <p>
          <Trans t={t} i18nKey="retentionOfSensitivePersonalInformation.descriptionTwo" />
        </p>
      </section>

      <section
        id={t('quickLinks.automatedDecisionMaking.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2>{t('automatedDecisionMaking.heading')}</h2>
        <p>{t('automatedDecisionMaking.description')}</p>
      </section>

      <section id={t('quickLinks.individualRights.id')} className="PrivacyStatement__generic-gap">
        <h2>{t('individualRights.heading')}</h2>
        <p className="PrivacyStatement__body-generic-gap">{t('individualRights.description')}</p>
        <h3 className="h4">{t('individualRights.withDrawConsent.heading')}</h3>
        <p className="PrivacyStatement__body-generic-gap">
          <Trans t={t} i18nKey="individualRights.withDrawConsent.description" />
        </p>
        <h3 className="h4">{t('individualRights.accessRectificationorDeletion.heading')}</h3>
        <p>
          <Trans
            t={t}
            i18nKey="individualRights.accessRectificationorDeletion.description"
            values={{
              emailTlc: emails.tlc,
              emailPrivacyOfficer: emails.privacyOfficer,
            }}
          >
            <ExternalLink
              href={`mailto:${emails.tlc}`}
              aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${emails.tlc}`}
            />
            <ExternalLink
              href={`mailto:${emails.privacyOfficer}`}
              aria-label={`${t(`${LocaleNameSpaceKeys.COMMON}:email`)} ${emails.privacyOfficer}`}
            />
            <ExternalLink href={CommonData.websites.equifax.creditReport[activeLocaleTag]} />
            <ExternalLink href={CommonData.websites.transUnion[activeLocaleTag]} />
            <ExternalLink href={CommonData.websites.equifax.dispute[activeLocaleTag]} />
            <ExternalLink href={CommonData.websites.transUnion.dispute[activeLocaleTag]} />
          </Trans>
        </p>
      </section>

      <section
        id={t('quickLinks.informationAboutWebsite.id')}
        className="PrivacyStatement__generic-gap"
      >
        <h2>{t('informationAboutOurWebsite.heading')}</h2>
        <Trans
          i18nKey="informationAboutOurWebsite.description"
          t={t}
          values={{
            website: CommonData.mainWebsiteDomain,
          }}
        >
          <ExternalLink href={`https://${CommonData.mainWebsiteDomain}`} />
          <ExternalLink href={`https://${CommonData.mainWebsiteDomain}`} />
          <ExternalLink href={`https://${CommonData.mainWebsiteDomain}`} />
        </Trans>
      </section>

      <section id={t('quickLinks.legalDisclaimer.id')} className="PrivacyStatement__generic-gap">
        <h2>{t('legalDisclaimers.heading')}</h2>
        <Trans i18nKey="legalDisclaimers.description" t={t} />
      </section>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.PRIVACY_STATEMENT)(PrivacyStatement);
