import React from 'react';
import { Trans } from 'react-i18next';

export const renderBodyWithList = (
  content: {
    body: string;
    list?: string[];
  }[]
) => (
  <>
    {content.map((contentItem) => (
      <>
        {contentItem.body ? (
          <p>
            <Trans>{contentItem.body}</Trans>{' '}
          </p>
        ) : null}

        {contentItem.list ? (
          <ul>
            {contentItem.list.map((listItem, i) => (
              <li key={`${listItem.length}-${i}`}>{listItem}</li>
            ))}
          </ul>
        ) : null}
      </>
    ))}
  </>
);
