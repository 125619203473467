import React from 'react';
import { Trans } from 'react-i18next';

import { renderBodyWithList } from './content';

export const getTableRows = (
  rows: any[],
  options: {
    useDangerousHTML: boolean;
  } = { useDangerousHTML: true }
) => {
  return rows.map((columnArray: any, i: number) => {
    return (
      <tr key={`${columnArray.toString()}-${i}`}>
        {columnArray.map((col: any, j: number) => {
          let className = '';

          if (col.cs) {
            className += `col-cs-${col.cs}`;
          }

          if (col.rs) {
            className += `col-rs-${col.rs}`;
          }

          const props: any = {
            rowSpan: col.rs,
            colSpan: col.cs,
            className,
          };

          if (options?.useDangerousHTML) {
            props.dangerouslySetInnerHTML = { __html: col.value };
          } else {
            props.children =
              typeof col.value !== 'string' && col.value.content ? (
                renderBodyWithList(col.value.content)
              ) : (
                <Trans>{col.value}</Trans>
              );
          }

          return col.heading ? (
            <th key={`${col.value}-${i}-${j}`} {...props} />
          ) : (
            <td key={`${col.value}-${i}-${j}`} {...props} />
          );
        })}
      </tr>
    );
  });
};
